<template>
  <div>
      <!-- Message if no inputs to show -->
      <p v-if="inputs == 0" class="text-activity-notes enpty-attr text-left">
        {{ $t("components.Inputs.no-input") }}
      </p>
      <p v-else-if="inputs === undefined" class="text-activity-notes text-left">
        {{ $t("activities.multiple.details.inputs") }}
      </p>
      <div v-else>
        <div class="mb-2" v-for="input in inputs" v-bind:key="input.id">
          <!-- input stock infos -->
          <b-row class="px-0 m-0 br-bottom-0 bg-white text-infos" v-if="input.dose === undefined">
            <b-col cols="6" class="font-weight-bold">{{ input.inputsStock_type + " - " + input.inputsStock_name }}</b-col>
            <b-col cols="6" class="text-right">{{ $t("activities.multiple.details.dose") }}</b-col>
          </b-row>

          <b-row class="px-0 m-0 br-bottom-0 bg-white text-infos" v-else>
            <b-col cols="8" class="font-weight-bold">{{ input.inputsStock_type + " - " + input.inputsStock_name }}</b-col>
            <b-col cols="4" class="text-right">{{ fixedDigits(input.dose, 4) || 0 }} {{ input.doseUnit_unit }}</b-col>
          </b-row>

          <!-- input stock seed density infos -->
          <b-row class="px-0 m-0 br-bottom-0 bg-white text-infos" v-if=" input.seedDensity && input.seedDensity != null ">
            <b-col cols="8" class="input-label">{{ $t("components.EditInputs.seedDensity") }} :</b-col>
            <b-col cols="4" class="text-right text">{{ input.seedDensity }} {{ input.seedDensityUnit_unit }}</b-col>
          </b-row>

          <!-- input stock seed treatment infos -->
          <b-row class="px-0 m-0 br-bottom-0 bg-white text-infos" v-if="showTreatment && input.inputsStock_treated == false && input.productFamily_code === 'sem'">
            <b-col cols="4" class="input-label"  >{{ $t("components.EditInputs.seedTreatment") }} :</b-col>
            <b-col cols="8" class="text-right" >{{ getString(input.treatments) }} </b-col>
          </b-row>

          <!-- input stock filledQuantity -->
          <b-row class="px-0 m-0 br-top-0 bg-grey text-infos" v-if=" input.filledQuantity ">
            <b-col cols="5" class="text-dark-grey">{{ $t("components.Inputs.volume-used") }}</b-col>
            <b-col cols="7" class="text-right">{{ formatNumber(fixedDigits(input.filledQuantity, 5)) }} {{getRelatedDoseUnit(input.doseUnit) }}</b-col>
          </b-row>
        
          <!-- input stock volumes details -->
          <b-row class="px-0 m-0 br-top-0 bg-grey text-infos" v-if="showVolumes">
            <b-col cols="5" class="text-dark-grey">{{ $t("components.Inputs.volume-to-use") }}</b-col>
            <b-col cols="7" class="text-right" v-if="isGenericTour">({{ input.dose }} x {{ totalSurface }}) = {{ totalVolume(input) }} {{getRelatedDoseUnit(input.doseUnit) }}</b-col>
            <b-col cols="7" class="text-right" v-else>{{ totalVolume(input) }} {{getRelatedDoseUnit(input.doseUnit) }}</b-col>
          </b-row>
          
        </div>

        <!-- input stock total volume -->
        <b-row class="px-0 m-0 mb-1 bg-grey text-infos" v-if="showMixtureVolumes">
          <b-col cols="6" class="text-dark-grey">{{ $t("components.Inputs.mixture-volume") }}</b-col>
          <b-col cols="6" class="text-right">{{ currentEntry.mixtureVolume }} L/ha</b-col>
          <b-col cols="6" class="text-dark-grey">{{ $t("components.Inputs.total-volume") }}</b-col>
          <b-col cols="6" class="text-right" v-if="isGenericMixtureVolume">({{currentEntry.mixtureVolume }} x {{ totalSurface }}) = {{ totalMixtureVolume }} L</b-col>
          <b-col cols="6" class="text-right" v-else>{{ totalMixtureVolume }} L</b-col>
        </b-row>
      </div>
  </div>

</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import { fixDigits, formatNumber} from "@/utils/commons";

export default {
  mixins: [DetailUpdateMixin],
  name: "inputs",
  props: {
    showVolumes: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      doseUnits: "doseUnits"
    }),
    cropfields() {
      return this.currentEntry.cropFields;
    },
    inputs() {
      return this.currentEntry.inputs;
    },
    totalSurface() {
      return _.round(this.cropfields?.map((c) => c.surfaceToWork ? parseFloat(c.surfaceToWork) : c.cropField_surface).reduce((a, b) => a + b, 0), 2);
    },
    totalQuantity() {
      return _.round(this.inputs.map((c) => (c.quantity || c.dose)).reduce((a, b) => a + b, 0));
    },
    /* when do we show mixture (bouillie) volumes ? */
    showMixtureVolumes() {
      return this.showVolumes // the showVolumes mode is activated
        && this.currentEntry.activityType_code == "pulve" // we are in pulve family
        && this.inputs.length > 0 // there are inputs
        && Number.isFinite(this.currentEntry.mixtureVolume) && this.currentEntry.mixtureVolume !== 0  // there is a mixture volume value
    },
    showTreatment(){
      return this.currentEntry.activityType_code && ["semis", "plant", "combi"].includes(this.currentEntry.activityType_code);
    },
    /**
     * Permet de savoir si une tournée est générique ou non
     * Fais une boucle sur les intrants de chaque parcelle et vérifie s'il y en a qui ont des doses différentes de celles génériques
     */
    isGenericTour() {
      let isGeneric = true;
      let test_input = null;
      this.cropfields.forEach(cf => {
        cf.inputs.forEach(cf_input => {
          test_input = this.inputs.find(input => input.inputsStock == cf_input.inputsStock && input.dose != cf_input.dose)
          if (test_input) {
            isGeneric = false;
          }
        })
      });
      return isGeneric;
    },
    /**
     * Retourne true si aucune n'a de volume de bouillie spécifique et false sinon
     */
    isGenericMixtureVolume() {
      let isGeneric = true;
      this.cropfields.forEach(cf => {
        if (cf.mixtureVolume && cf.mixtureVolume != this.currentEntry.mixtureVolume) {
          isGeneric = false;
        }
      });
      return isGeneric;
    },
    /**
     * Volume de mixture total
     * Prend en compte les volumes spécifiques des parcelles
     */
    totalMixtureVolume() {
      let somme = 0;

      this.cropfields.forEach(cf => {
        somme = somme + parseFloat(cf.mixtureVolume || this.currentEntry.mixtureVolume || 0) * parseFloat((cf.surfaceToWork || cf.cropField_surface));
      })

      return _.round(somme, 2);
    }
  },
  mounted() {
  },
  methods: {
    round: _.round,
    formatNumber,
    getString(treatments){
      // Method to concatenate all the input.treatments to display in a single column(b-col)
      let treatmentsToString = "";
      treatments.map((t, index) => {
        treatmentsToString = (index != treatments.length-1) ? treatmentsToString + t.refProduct_name + ", " :  treatmentsToString + t.refProduct_name ;
      })
      return treatmentsToString ;
    },
    getRelatedDoseUnit(unit_id) {
      return this.doseUnits.find(d => d.id == unit_id)?.relatedUnit_unit
    },
    fixedDigits(value, numberOfDigits) {
      return fixDigits(value, numberOfDigits)
    },
    /**
     * Détermine le volume total d'un intrant
     * Prend en compte les quantités spécifiques
     */
    totalVolume(input) {
      let somme = 0;
      let test_input;

      this.cropfields.forEach(cf => {
        test_input = cf.inputs.find(cf_input => cf_input.inputsStock == input.inputsStock);
        if (test_input ) {
          somme = somme + parseFloat(test_input.dose || test_input.quantity || 0) * parseFloat((cf.surfaceToWork || cf.cropField_surface));
        } else {
          somme = somme + parseFloat(input.dose || input.quantity || 0) * parseFloat((cf.surfaceToWork || cf.cropField_surface));
        }
      })

      return _.round(somme, 2);
    },
  }
};
</script>

<style lang="scss" scoped>
/**
 * Overload subtitle-
 */
.overload-subtitle {
  margin: 0px 0px 0px 0px;
}

/**
 * Color text for empty list.
 */
.enpty-attr {
  text-align: center;
  color: $danger-color;
}

.input-label {
  font-weight: bold;
  color: $subtitle-color;
}
</style>
